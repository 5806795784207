import { Injectable } from "../../../lib/web/reflection/injectable";

@Injectable({ type: 'singleton' })
export class KeyboardService {

    private _activeElement: Element = null;

    public constructor() {
        setInterval(() => {
            if (document.activeElement != this._activeElement) {
                this._activeElement = document.activeElement;
                console.log(this._activeElement);
            }
        }, 100);
    }

    public disableKeyboardTab(exclude: HTMLElement | string): void {
        const elements: HTMLElement[] = Array.from(document.querySelectorAll('input, a, button'));
        elements.forEach(e => {
            if (!this.isParent(e, exclude)) {
                if (e.getAttribute('tabindex')) {
                    e.dataset.tabindex = e.getAttribute('tabindex');
                }
                e.setAttribute('tabindex', '-1');
            }
        });
    }

    private isParent(e: HTMLElement, parent: HTMLElement | string): boolean {
        if (typeof parent == 'string') {
            return !!e.closest(parent);
        }
        else {
            return parent.contains(e);
        }
    }

    public enableKeyboardTab(): void {
        const elements: HTMLElement[] = Array.from(document.querySelectorAll('[tabindex]'));
        elements.forEach(e => {
            if (e.dataset.tabIndex) {
                e.setAttribute('tabindex', e.dataset.tabIndex);
                e.dataset.tabIndex = undefined;
            }
            else {
                e.removeAttribute('tabindex');
            }
        });
    }
}