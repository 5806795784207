import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';

@Component({
    selector: '.a-quality-menu'
})

export class QualityMenuComponent extends ComponentBase<HTMLInputElement> {

    private _qualities: { id: string, display: string, isSelected: boolean }[] = null;
    private _selectedQuality: string = null;

    @ChildRef('ul')
    private _listElement: HTMLUListElement = null;

    public constructor(node: HTMLInputElement) {
        super(node);
    }

    private show(): void {
        this.addClass('a-quality-menu--show');
        this._listElement.querySelectorAll('li').forEach(qualityElement => {
            qualityElement.tabIndex = 0;
        });
    }

    public hide(): void {
        this.removeClass('a-quality-menu--show');
        this._listElement.querySelectorAll('li').forEach(qualityElement => {
            qualityElement.tabIndex = -1;
        });
    }

    public toggle(): void {
        if (this._node.classList.contains('a-quality-menu--show')) {
            this.hide();       
        } 
        else {
            this.show();
        }
    }

    public get qualities(): { id: string, display: string, isSelected: boolean }[] {
        return this._qualities;
    }

    public set qualities(value: { id: string,  display: string, isSelected: boolean }[]) {
        this._qualities = value
            .filter((value, index, self) => self.findIndex(v => v.display === value.display) === index)
            .sort((a, b) => a.id < b.id ? 1: -1);
        // In case the selected quality got deleted lets select the one that was left with same height
        if (this._qualities.length && !this._qualities.some(quality => quality.isSelected)) {
            const previousSelectedQuality = value.find(e => e.id === this._selectedQuality);
            this._qualities.find(quality => quality.display === previousSelectedQuality.display).isSelected = true;
            this._selectedQuality = this._qualities.find(quality => quality.isSelected).id;
        }

        this.setQualities();
    }

    public get selectedQuality(): string {
        return this._selectedQuality;
    }

    public set selectedQuality(value:string) {
        this._selectedQuality = value;
    }

    private qualitiesClick(quality?: any) {
        this._selectedQuality = quality ? quality.id : 'auto';
        this.dispatchCustomEvent('selection-changed');
        this.hide();
        this.updateQualities();
    }

    private setQualities(): void {
        this._listElement.innerHTML = null;
        this._qualities.forEach(quality => {
            const qualityElement: HTMLLIElement = document.createElement('li');
            const subLabel = this.getSubLabel(parseInt(quality.display));
            if (subLabel === 'HD') {
                qualityElement.classList.add('a-quality-menu__item--HD-flag');
                qualityElement.classList.remove('a-quality-menu__item--4K-flag');
            } 
            else if (subLabel === '4K') {
                qualityElement.classList.remove('a-quality-menu__item--HD-flag');
                qualityElement.classList.add('a-quality-menu__item--4K-flag');
            } 
            else {
                qualityElement.classList.remove('a-quality-menu__item--HD-flag');
                qualityElement.classList.remove('a-quality-menu__item--4K-flag');
            }
            qualityElement.innerHTML = `${quality.display}p`;
            qualityElement.role = 'button'
            qualityElement.dataset.id = quality.id;
            qualityElement.addEventListener('click', () => this.qualitiesClick(quality));
            qualityElement.addEventListener('keypress', (event) => {
                if (event.key === 'Enter') {
                    this.qualitiesClick(quality);
                }
            });
            this._listElement.appendChild(qualityElement);
        });
        const autoQualityElement: HTMLLIElement = document.createElement('li');
        autoQualityElement.innerHTML = 'Auto';
        autoQualityElement.role = 'button';
        autoQualityElement.classList.add('a-quality-menu__item--selected');
        autoQualityElement.dataset.id = 'auto';
        autoQualityElement.addEventListener('click', () => this.qualitiesClick());
        autoQualityElement.addEventListener('keypress', (event) => {
            if (event.key === 'Enter') {
                this.qualitiesClick();
            }
        });
        this._listElement.ariaSetSize = (this._qualities.length + 1).toString();
        this._listElement.appendChild(autoQualityElement);
    }

    private updateQualities() {
        this._listElement.querySelectorAll('li').forEach(qualityElement => {
            qualityElement.classList.remove('a-quality-menu__item--selected');
            if (qualityElement.dataset.id === this._selectedQuality) {
                qualityElement.classList.add('a-quality-menu__item--selected');
            }
        });
        this._qualities.forEach(quality => {
            quality.isSelected = false;
            if (this.selectedQuality === quality.id) {
                quality.isSelected = true;
            }
        });
    }

    private getSubLabel(height: number) {
        if (height >= 2160) {
          return '4K';
        }
        else if (height >= 720) {
          return 'HD';
        }
        else {
            return '';
        }
    };

    public unload(): void {
        this._qualities = null;
        this._selectedQuality = null;
    }

    public dispose(): void {
        this.hide();
        super.dispose();
    }
}