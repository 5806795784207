import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { Key } from '../../../../../lib/web/core/key';
import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ChildrenRef } from '../../../../../lib/web/components/children-ref';
import { EventListener } from '../../../../../lib/web/components/event-listener';
import { ApiService } from '../../../services/api.service';

@Component({
    selector: '.p-link-device'
})
export class LinkDeviceComponent extends ComponentBase<HTMLElement> {

    public constructor(node: HTMLElement, private _apiService: ApiService) {
        super(node);        
    }

    @ChildrenRef(HTMLInputElement, 'input[type="number"]')
    private _inputElements: HTMLInputElement[] = null;;

    @ChildRef('input[name=code]')
    private _codeElement: HTMLInputElement = null;

    @ChildRef('.p-link-device__error')
    private _errorElement: HTMLDivElement = null;

    public onInit(): void {
        this._inputElements[0].focus();       
    }
    
    @EventListener('input', 'input[type="number"]')
    public onInputElement(event: Event): void {
        const inputElement: HTMLInputElement = event.target as HTMLInputElement;
        const index: number = this._inputElements.indexOf(inputElement);
        // Update focus if value is set with 1 number
        if (inputElement.value.length === 1 && index + 1 < this._inputElements.length) {
            this._inputElements[index+1].focus();
        }
        // Paste case
        if (inputElement.value.length > 1) {
            //If pasted is not a number
            if (isNaN(Number(inputElement.value))) {
                inputElement.value = '';
                this.updateInput();
                return;
            }
            // Fill all inputs with integers
            const chars: string[] = inputElement.value.split('');
            for(let pos: number = 0; pos < chars.length; pos++) {
                // If pasted is longer than inputs
                if (pos + index >= this._inputElements.length) {
                    break;
                }
                // Fill target input with value
                const targetInput: HTMLInputElement = this._inputElements[pos + index];
                targetInput.value = chars[pos];
            }
            // Focus last empty input
            let focusIndex: number = Math.min(this._inputElements.length -1, index + chars.length);
            this._inputElements[focusIndex].focus();
        }
        this.updateInput();
    }

    @EventListener('keydown', 'input[type="number"]')
    public onKeydownElement(event: KeyboardEvent): void {
        const inputElement: HTMLInputElement = event.target as HTMLInputElement;
        const index: number = this._inputElements.indexOf(inputElement);
        if (event.key == 'Backspace' && inputElement.value == '' && index != 0) {
            for (let pos: number = index; pos < this._inputElements.length - 1; pos++) {
                this._inputElements[pos].value = this._inputElements[pos + 1].value;
            }
            this._inputElements[index-1].value = '';
            this._inputElements[index-1].focus();
            this.updateInput();
            return;
        };
        if (event.key == 'Delete' && index != this._inputElements.length - 1) {
            for (let pos: number = index; pos < this._inputElements.length - 1; pos++) {
                this._inputElements[pos].value = this._inputElements[pos + 1].value;
            }      
            // clear the last box
            this._inputElements[this._inputElements.length - 1].value = '';
            inputElement.select();
            event.preventDefault();
            this.updateInput();
            return;
        };
        if (event.key == Key.ArrowLeft) {
            if (index > 0) {
              event.preventDefault();
              this._inputElements[index-1].focus();
              this._inputElements[index-1].select();
            }
            return;
        };
        if (event.key == Key.ArrowRight) {
            if (index + 1 < this._inputElements.length) {
              event.preventDefault();
              this._inputElements[index+1].focus();
              this._inputElements[index+1].select();
            }
            return;
        }
    }

    private async updateInput(): Promise<void> {
        const inputValue: string = Array.from(this._inputElements).reduce(function (otp, input) {
            otp += (input.value.length) ? input.value : ' ';
            return otp;
        }, '');
        this._codeElement.value = inputValue;
        this.hideError();
        if (inputValue.length === 6 && /^\d+$/.test(inputValue)) {
            try {
                const { isValid } = await this._apiService.get(`/api/v1/user/smart-login/${parseInt(inputValue, 10)}/validate`);
                if (isValid) {
                    this.redirect(`/u/smart-login/${inputValue}`);
                }
                else {
                    this.showError();    
                }
            }
            catch (e: any) {
                console.error(e);
                this.showError();
            }
        }
    }

    private hideError(): void {
        if (!this._errorElement.classList.contains('u-hidden')) {
            this._errorElement.classList.add('u-hidden');
            this._inputElements.forEach(i => {
                i.classList.remove('u-error-input');
                i.setAttribute('aria-invalid', 'false');
            });
        }
    }

    private showError(): void {
        if (this._errorElement.classList.contains('u-hidden')) {
            this._errorElement.classList.remove('u-hidden');
            this._inputElements.forEach(i => {
                i.classList.add('u-error-input');
                i.setAttribute('aria-invalid', 'true');
            });
        }
    }
}