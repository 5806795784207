import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ChildrenRef } from '../../../../../lib/web/components/children-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { EventListener } from '../../../../../lib/web/components/event-listener';
import { Guid } from '../../../../../lib/web/core/guid';
import { ApiService } from '../../../services/api.service';
import { MediaOptions, MediaPlayerService } from '../../../services/media-player.service';
import { SessionService } from '../../../services/session.service';
import { UserWatchingHistoryService } from '../../../services/user-watching-history.service';
import { ValidationService } from '../../../services/validation.service';
import { PlayerComponent } from '../../molecules/player/player.component';

@Component({
    selector: '.o-media-player'
})
export class MediaPlayerComponent extends ComponentBase<HTMLElement> {
      
    private _userWatchingHistoryInterval: NodeJS.Timer = null;
    private _lastCurrentTime: number = null;
    private _isLastCollectionChapter: boolean = false;
    private _nextCapsuleId: number = null;
    private _nextCapsuleDuration: number = null;
    private _options: MediaOptions = null;
    private _lastUserUpdateCurrentTime: number = null;
    private _lastTextTrackLanguage: string = null;
    private _lastAudio: string = null;
    private _playId: string = null;

    @ChildRef()
    private _player: PlayerComponent = null;
    @ChildRef('.o-media-player__rating')
    private _ratingElement: HTMLDivElement = null;
    @ChildRef('.o-media-player__image')
    private _image: HTMLImageElement = null;
    @ChildRef('#parentalPin')
    private _parentalPinInput: HTMLInputElement = null;
    @ChildrenRef(HTMLInputElement, '.a-input-pin__code')
    private _pinInputsElement: HTMLInputElement[];

    public constructor(node: HTMLElement, 
        private _apiService: ApiService, 
        private _sessionService: SessionService, 
        private _mediaPlayerService: MediaPlayerService,
        private _validationService: ValidationService,
        private _userWatchingHistoryService: UserWatchingHistoryService) {
        
        super(node);

        this._mediaPlayerService.setMediaPlayer(this);
        this.isDisposable = false;

        this.addCustomWindowEventListener('user-sign-out', () => {
            if ((this._options || {}).type == 'capsule') {
                this.close();
            }
        });
    }

    public onInit(mode?: 'load' | 'redirect'): void {
        super.onInit(mode);
        this._player.setDisposable(false);
        ['hidding', 'ended', 'pause', 'text-track-changed', 'audio-track-changed'].forEach(e => this._player.addCustomEventListener(e, () => {
            if ((this._options || {}).type == 'capsule') {
                this.addUserWatchingHistory();
            }
        })); 
        ['user-update-current-time'].forEach(e => this._player.addCustomEventListener(e, () => {
            if ((this._options || {}).type == 'capsule') {
                if (!this._lastUserUpdateCurrentTime)
                setTimeout(() => {
                    this._lastUserUpdateCurrentTime = null;
                    this.addUserWatchingHistory();
                }, 2000);
                this._lastUserUpdateCurrentTime = this._player.currentTime;
            }
        }));
        this._player.addCustomEventListener('percentage-seen', e => {
            if (e.percentage == CONFIG.CONTENT_SEEN_PERCENTAGE) {
                this.addContentSeen();
            }
        });
    }

    @ClickListener('.o-media-player-link')
    public onLinkClose(): void {
        this.close(false);
    }

    @ClickListener('.o-media-player__back')
    public onBack(): void {
        this.close();        
    }

    @EventListener('back', '.m-player')
    public onPlayerBack(): void {
        this.onBack();
    }

    @EventListener('player-close', '.m-player')
    public onPlayerClose(): void {
        this.close();
    }

    @EventListener('previous-chapter', '.m-player')
    public onPreviousChatper(e: CustomEvent): void {        
        const { collectionId, type, image } = this.options;
        this.load({
            capsuleId: e.detail.capsuleId,
            collectionId,
            type,
            image,
            owner: {
                pathname: e.detail.url,
                target: e.detail.url
            },
            controls: {
                enableFullScreen: false
            }
        });
    }

    @EventListener('next-chapter', '.m-player')
    public onNextChatper(e: CustomEvent): void {        
        const { collectionId, type, image } = this.options;
        this.load({
            capsuleId: e.detail.capsuleId,
            collectionId,
            type,
            image,
            owner: {
                pathname: e.detail.url,
                target: e.detail.url
            },
            controls: {
                enableFullScreen: false
            }
        });
    }

    @EventListener('input', 'input')
    public async onChange(): Promise<void> {
        const parentalPin = this._parentalPinInput.value;
        if (parentalPin.length === 4) {
            this.submitParentalPin(parentalPin);
        }
    }

    @EventListener('mini-player-activated', '.m-player')
    public onMiniPlayerActivated(): void {
        this._node.dataset.miniplayer = 'true';
        this._mediaPlayerService.miniPlayerActivated();
    }

    @EventListener('mini-player-deactivated', '.m-player')
    public onMiniPlayerDeactivated(e: CustomEvent): void {
        delete this._node.dataset.miniplayer;
        if (e.detail.closing) {
            this.close();
        }        
        this._mediaPlayerService.miniPlayerDeactivated();
        this.checkOwner();
    }

    private checkOwner(): void {
        const { owner } = this.options || {};
        if (owner) {            
            const { pathname, target } = owner;        
            if (!location.pathname.startsWith(pathname)) {
                this.redirect(target);
            }
        }
    }

    public async load(options: MediaOptions): Promise<void> {
        this._playId = Guid.newGuid();

        if (this._player.isMiniPlayerActive) {
            this._player.closeMiniPlayer();
        }
        if (this._player.isLoaded) {
            this._player.unload(false);
        }
        this._options = options;
        const { type, capsuleId, collectionId, image, video, controls, currentTime, parentalPin } = options;
        if (image) {
            this._image.src = image;
        }
        try {
            if (type == 'video') {
                const { id, title } = video;
                this._player.load({
                    videoId: id,
                    title: title,
                    closeOnEnd: true
                }, {
                    controls: false,
                    miniPlayer: (controls || {}).miniPlayer,
                    enableFullScreen: (controls || {}).enableFullScreen
                });
                this.hideBodyOverflow();
                this.removeClass('o-media-player-error');
                this.addClass('o-media-player--opened');    
            }
            else if (type == 'trailer' || capsuleId) {
                    const parentalPinCode = parentalPin ? `/${parentalPin}` : '';
                    const response: any = type == 'capsule' 
                        ? await this._apiService.get(`/api/v1/capsule/${capsuleId}/metadata${parentalPinCode}`)
                        : capsuleId 
                            ? await this._apiService.get(`/api/v1/capsule/${capsuleId}/trailer-metadata${parentalPinCode}`)
                            : await this._apiService.get(`/api/v1/collection/${collectionId}/trailer-metadata${parentalPinCode}`);


                    if (!response.capsule?.brightcoveId && !response.trailer.brightcoveId) {
                        this.logPlayerMetadata(this._playId, 'afterResponse', response.capsule);
                    }
                            
                    if (type == 'capsule') {
                        this._isLastCollectionChapter = response.isLastCollectionChapter;
                        this._nextCapsuleId = response.next?.capsuleId;
                        this._nextCapsuleDuration = response.next?.duration;
                        this._lastCurrentTime = null;
                        this.clearUserWatchingHistoryInterval();
                        this._userWatchingHistoryInterval = setInterval(() => {
                            this.addUserWatchingHistory();
                        }, CONFIG.USER_WATCHING_HISTORY_INTERVAL);
                
                        this._player.load({
                            videoId: response.capsule.brightcoveId,
                            display: response.capsule.display,
                            currentTime: currentTime || this._userWatchingHistoryService.getCurrentTime(capsuleId, 'capsule'),
                            isContinueWatching: this._userWatchingHistoryService.isWatching(capsuleId, 'capsule'),
                            title: response.title,
                            previous: response.previous,
                            next: response.next,
                            related: response.related,
                            rating: response.capsule.rating,
                            ratingTags: response.capsule.ratingTags,
                            disciplines: response.capsule.disciplines,
                            typology: response.capsule.typology,
                            progressPercentages: [CONFIG.CONTENT_SEEN_PERCENTAGE],
                            segments: response.capsule.segments,
                            subtitle: this._userWatchingHistoryService.getSubtitle(capsuleId, 'capsule', collectionId) || CONFIG.DEFAULT_SUBTITLE_ID,
                            audio: this._userWatchingHistoryService.getAudio(capsuleId, 'capsule', collectionId),
                            isUserRequired: response.isUserRequired,
                        }, {
                            controls: false,
                            miniPlayer: (controls || {}).miniPlayer,
                            enableFullScreen: (controls || {}).enableFullScreen,
                            playId: this._playId
                        });    
                    }
                    else {
                        this._player.load({
                            videoId: response.trailer.brightcoveId,
                            display: response.trailer.display,
                            title: response.title,
                            rating: response.trailer.rating,
                            ratingTags: response.trailer.ratingTags,
                            closeOnEnd: true,
                            isUserRequired: response.isUserRequired,
                        }, {
                            controls: false,
                            miniPlayer: (controls || {}).miniPlayer,
                            enableFullScreen: (controls || {}).enableFullScreen,
                            playId: this._playId
                        });
                    }
                    this.hideBodyOverflow();
                    this.removeClass('o-media-player-error');
                    this.addClass('o-media-player--opened'); 
                    this.disableKeyboardTab('.o-media-player');
            }
            else {
                this.showUnathorizedError();
            }
            this.checkOwner();
        }
        catch (e: any) {
            this.logPlayerMetadata(this._playId, type === "trailer" ? 'trailerError' : !collectionId ? 'collectionError' : 'capsuleError', e);
            switch (e.statusCode) {
                case 409:
                    this.showError(JSON.parse(e.data || e));
                    break;
                case 401:
                    this.showUnathorizedError();
                    break;
                default:
                    this.showUnexpectedError(e);
                    break;
            }                
        }
    }

    public async addUserWatchingHistory(): Promise<void> {
        if (this._sessionService.isSigned) {
            const { type, capsuleId, collectionId } = this._options || {};
            if (type == 'capsule') {
                const { currentTime, duration, isFirstPlay, isLoaded, selectedTextTrackLanguage, selectedAudioTrack } = this._player;
                if (isLoaded && isFirstPlay && (this._lastCurrentTime != currentTime || this._lastTextTrackLanguage != selectedTextTrackLanguage || this._lastAudio != selectedAudioTrack) && currentTime && duration) {
                    this._lastTextTrackLanguage = selectedTextTrackLanguage;
                    this._lastAudio = selectedAudioTrack;
                    this._lastCurrentTime = currentTime;
                    
                    this._userWatchingHistoryService.add({
                        capsuleId: capsuleId,
                        collectionId: collectionId,
                        isLastCollectionChapter: this._isLastCollectionChapter,
                        currentTime,
                        durationTime: duration, 
                        nextCapsuleId: this._nextCapsuleId,
                        nextCapsuleDurationTime: this._nextCapsuleDuration,
                        subtitle: selectedTextTrackLanguage,
                        audio: selectedAudioTrack
                    });
                }
            }
        }
    }

    private clearUserWatchingHistoryInterval(): void {
        if (this._userWatchingHistoryInterval) {
            clearInterval(this._userWatchingHistoryInterval);
        }
    }

    public async addContentSeen(): Promise<void> {        
        const { type, capsuleId, collectionId } = this._options || {};
        if (type == 'capsule') {
            logger.debug(`👀 content seen ${CONFIG.CONTENT_SEEN_PERCENTAGE}%: ${collectionId ? 'collection': 'capsule'}-${collectionId || capsuleId}`);
            this._apiService.post(`/api/v1/content/seen`, {
                body: {
                    capsuleId,
                    collectionId
                }
            });
        }
    }

    public close(raiseEvent: boolean = true): void {
        this.enableKeyboardTab();
        this._node.classList.remove('o-media-player--opened', 'o-media-player--error');
        this._validationService.resetErrors('.o-media-player__error-pin', { parentalPin: this._parentalPinInput?.value || '' });
        this.showBodyOverflow();
        this.clearUserWatchingHistoryInterval();
        if (!this._player.videoId) {
            this.logPlayerMetadata(this._playId, 'beforeUnload');
        }                    
        this._player.unload();
        this._options = null;
        this._image.src = '';
        if (raiseEvent) {
            this._mediaPlayerService.playerClosed();
        }
    }

    private async logPlayerMetadata(playId: string, step: string, metadata?: any): Promise<void> {
        this._apiService.post(`/api/v1/capsule/log-player-metadata/${playId}/${step}`, {
            body: metadata
        });
    }

    private async submitParentalPin(parentalPin: string): Promise<void> {
        try {
            // await this._apiService.get(`/api/v1/user/parental-pin/${parentalPin}`);
            await this._apiService.post('/api/v1/user/validate-parental-pin', {
                body: { parentalPin }
            });
            this.load({ ...this._options, parentalPin });
            this.close(false);
        }
        catch (e: any) {
            if (e.statusCode == 400 || e.statusCode == 403) {
                this._validationService.showErrors('.o-media-player__error-pin', { parentalPin: [e.data?.message] });
            }
            else {
                this.showUnexpectedError(e);
            }
        }
    }

    private clearPinInputs() {
        this._pinInputsElement.forEach(i => i.value = '');
    }
   
    private showError(data: { message: string }): void {
        const error: { errorCode: string, rating: any, image: string } = JSON.parse(data?.message);
        switch (error.errorCode) {
            case 'COUNTRY':
                this.showCountryError();
                break;
            case 'RATING':
                this.showRatingError(error.rating);
                break;
            default:
                this.showUnexpectedError(data);
                break;
        }
    }

    private showCountryError(): void {
        this.setError(USER_LOCALE.countryErrorRestriction);
    }

    private async showRatingError(rating: any): Promise<void> {
        this.setError(USER_LOCALE.ratingErrorRestriction.format(rating.age), USER_LOCALE.ratingErrorRestrictionDescription, rating);
        const user = await this._apiService.get('/api/v1/user/me');
        if (user.parentalPinCreated) {
            this.clearPinInputs();
            this.removeClass('u-hidden-important', '.o-media-player__error-pin');
        } else {
            this.removeClass('u-hidden-important', '.o-media-player__settings');
        }
    }
    
    private showUnathorizedError(): void {
        this.setError(USER_LOCALE.registerForWatch);
        this.removeClass('u-hidden-important', '.o-media-player__register-actions');
    }

    private setError(title: string, description?: string, rating?: any): void {
        this.hideBodyOverflow();
        this.addClass('u-hidden-important', '.o-media-player__settings');
        this.addClass('u-hidden-important', '.o-media-player__register-actions');
        this.setInnerHTML(title, '.o-media-player__error-title');
        this.setInnerHTML(description || '', '.o-media-player__error-description');
        if (rating) {
            this._ratingElement.innerHTML = rating?.code;
            this._ratingElement.style.backgroundColor = rating?.color;   
            this._ratingElement.classList.remove('u-hidden')
        }
        else {
            this._ratingElement.classList.add('u-hidden')
        }
        this.addClass('o-media-player--error');
    }

    public get options(): MediaOptions {
        return this._options;
    }

    public get isMiniPlayerActive(): boolean {
        return this._player.isMiniPlayerActive;
    }
}